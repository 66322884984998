import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { OrderHistory } from 'src/app/views/order-history/order-history.model';
import OrderHistoryData from '../../../assets/data/orderHistory.json'

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  // SERVICES
  private http: HttpClient = inject(HttpClient);

  // ENDPOINT
  private endpoint: string = "/customer/order/history/";

  // STATE
  private _orderHistory: WritableSignal<OrderHistory | undefined> = signal(undefined);
  private _page:         WritableSignal<number>                   = signal(1);

  // COMPUTED
  public  page:          Signal<number>                           = computed(() => this._page());
  public  orderHistory:  Signal<OrderHistory | undefined>         = computed(() => this._orderHistory());
  public  tickets:       Signal<OrderHistory['results']>          = computed(() => this.orderHistory()?.results ?? []);

  // METHODS
  public getOrderHistory(reset: boolean = false) {
    
    if(reset){
      this.resetOrderHistory();
    }

    this.http.get<OrderHistory>(this.endpoint, {params: {page: this._page()}}).subscribe({
      next:  (orderHistory) => this._orderHistory.set(orderHistory),
      error: (error) => console.error(error)
    });

  }


  public next(): void {
    if(this.orderHistory()?.count! > this._page()){
      this._page.update(page => page + 1);
      this.getOrderHistory();
    }
  }

  public previous(): void {
    if(this._page() > 1 && this.orderHistory()?.count! > 1){
      this._page.update(page => page - 1);
      this.getOrderHistory();
    }
  }

  private resetOrderHistory() {
    this._orderHistory.set(undefined);
    this._page.set(1);
  }

}
