<section>

    <!-- MAIN CONTAINER AND FILTERS -->
    <div class="container mx-auto lg:py-5 lg:max-w-4xl xl:max-w-6xl">

        <!-- TITLE -->
        <h4 class="text-secondary text-2xl my-5"> Filter Orders </h4>

        <!-- FILTER FORM -->
        <form class="form" action="">
            <div class="flex lg:flex-row w-full">
                
                <!-- DATE FROM -->
                <div class="flex flex-col w-full lg:w-1/3 lg:mr-5">
                    <label for="order-id" class="text-secondary">
                        By Date From
                    </label>
                    <input type="text" id="order-id" class="input" placeholder="dd/mm/yy">
                </div>

                <!-- DATE TO -->
                <div class="flex flex-col w-full lg:w-1/3 lg:mr-5">
                    <label for="order-date" class="text-secondary">
                        To
                    </label>
                    <input type="date" id="order-date" class="input" placeholder="Order Date">
                </div>
                
                <!-- ORDER ID -->
                <div class="flex flex-col w-full lg:w-1/3">
                    <label for="order-status" class="text-secondary">
                        By Order ID
                    </label>
                    <input type="text" id="order-id" class="input" placeholder="Order Id">
                </div>
            </div>
        </form>

        <!-- ACTION BUTTONS -->
        <div class="w-full flex justify-end gap-x-2 my-2 lg:my-4">
            
            <!-- CLEAR FILTER -->
            <button class="inline-block button lg:w-1/6" disabled>
                Clear Filter
            </button>

            <!-- APPLY FILTER -->
            <button class="inline-block button lg:w-1/6">
                Apply Filter
            </button>

        </div>

    </div>

    <!-- RESULT VIEW -->
    <ng-container *ngIf="orderHistory() else loader">

        <!-- ORDER LIST -->
        <ng-container *ngIf="tickets().length else noTickets">
            
            <section class="bg-darkgray/5 w-full pb-10">
                <!-- ORDER ITEMS -->
                <app-order-history-item
                    [order]="order" 
                    *ngFor="let order of tickets()"
                />

                <!-- CONTROLS -->
                <ng-container *ngIf="orderHistory()?.count! > 1">
                    
                    <div class="flex justify-between h-10 items-center max-w-4xl mx-auto">
                        
                        <!-- PREV BUTTON -->
                        <div class="w-1/6">
                            <button (click)="orderHistoryService.previous()" [disabled]="page() === 1">
                                Previous
                            </button>
                        </div>

                        <!-- PAGE NUMBER -->
                        <div class="w-1/6">
                            <p class="text-center">
                                Page {{ page() }} of {{ orderHistory()?.count! }}
                            </p>
                        </div>

                        <!-- NEXT BUTTON -->
                        <div class="w-1/6">
                            <button (click)="orderHistoryService.next()" [disabled]="page() >= orderHistory()?.count!">
                                Next
                            </button>
                        </div>
    
                    </div>

                </ng-container>

            </section>

        </ng-container>

        <!-- NO TICKETS -->
        <ng-template #noTickets>
            <div class="container mx-auto">
                <p class="text-secondary text-center my-5">
                    No tickets found
                </p>
            </div>
        </ng-template>
        
    </ng-container>

    <!-- LOADER -->
    <ng-template #loader>
        <app-loader/>
    </ng-template>

</section>