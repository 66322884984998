<!-- ORDER ITEM -->
<div class="mx-auto flex justify-between items-start lg:py-6 lg:max-w-4xl xl:max-w-6xl border-b">
                    
    <!-- EVENT -->
    <div class="flex flex-col w-1/3">
        <p class="block text-secondary mb-1">
            Event
        </p>
        <p class="font-semibold text-darkgray">
            <!-- {{ order.title }} -->
        </p>

    </div>

    <!-- DATE -->
    <div class="flex flex-col w-1/4">
        <p class="block text-secondary mb-1">
            Date & Time
        </p>
        <p class="font-semibold text-darkgray">
            {{ order.date | date: 'd/M/yy h:mm a' }} 
        </p>
    </div>

    <!-- ORDER ID -->
    <div class="flex flex-col w-1/4">
        <p class="block text-secondary mb-1">
            Order Id
        </p>
        <p class="font-semibold text-darkgray">
            {{ order.order_id }}
        </p>
    </div>

    <!-- BUTTON -->
    <div class="flex w-1/5 self-center">

        <button class="button" (click)="visitOrder()">
            See Order
        </button>

    </div>

</div>