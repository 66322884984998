import { Component, inject, Signal } from '@angular/core';
import { HistoryService } from 'src/app/core/services/history.service';
import { OrderDetail, OrderHistory } from './order-history.model';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrl: './order-history.component.css'
})
export class OrderHistoryComponent {

  // SERVICES
  protected orderHistoryService: HistoryService = inject(HistoryService);

  // STATE
  public orderHistory: Signal<OrderHistory | undefined> = this.orderHistoryService.orderHistory;
  public page:         Signal<number>                   = this.orderHistoryService.page;
  public tickets:      Signal<OrderDetail[]>            = this.orderHistoryService.tickets;


  ngOnInit() {
    this.initComponent();
  }


  private initComponent() {
    this.orderHistoryService.getOrderHistory();
  }
}
